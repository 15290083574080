import { useDispatch } from 'react-redux';
import ReactSlider from 'react-slider';
import {
    Attribute,
    ColoringMode,
    HasAttributes,
    HasColoringMode,
    HasOverlayColor,
    LayerState,
    hasAttributes,
    hasOpacityCurve,
} from 'types/LayerState';
import * as layersSlice from 'redux/layers';

// Components
import { Color } from 'three';
import ColorMap, { COLORMAP_BOUNDSMODE, COLORMAP_NAMES, getLUT } from 'types/ColorMap';
import { useEffect, useState } from 'react';
import { toHex, Unit } from 'types/common';
import { useAppSelector } from 'store';
import { CurveKnot, getDefaultOpacityCurveKnots } from 'types/Curve';
import { useEventBus } from 'EventBus';
import { PANE } from 'services/Constants';
import Select from 'components/dropdown/Select';
import Toggle from 'components/dropdown/Toggle';
import Input from 'components/dropdown/Input';
import Button from 'components/dropdown/Button';
import ColorPicker from 'components/dropdown/ColorPicker';
import { Option } from 'components/Select';
import AttributeSelector from './AttributeSelector';
import ColormapScaleBar from './ColormapScaleBar';
import CurveEditor from './CurveEditor';

type LegendProps = {
    attribute: Attribute;
    colorMap: ColorMap;
    layer: LayerState & HasAttributes;
    pinned: boolean;
    scale: JSX.Element;
    minColor: Color;
    maxColor: Color;
    onMinBoundChanged: (min: number) => void;
    onMaxBoundChanged: (min: number) => void;
    onPinLegend: (pin: boolean) => void;
};

const LegendTool = (props: LegendProps) => {
    const { layer, scale, minColor, maxColor, attribute, colorMap } = props;
    const pinned = useAppSelector(layersSlice.isPinnedLegend(layer));

    const disabled = colorMap.boundsMode === COLORMAP_BOUNDSMODE.DATA;

    const unit = attribute.unit !== Unit.None ? attribute.unit : '';

    function to2DecimalPlaces(num: number): number {
        return Math.round(num * 100) / 100;
    }

    const roundDataBound = (bound: number): number => {
        if (bound === 0) return 0;
        const power = Math.log10(Math.abs(bound));
        if (power < 0) {
            return parseFloat(bound.toFixed(Math.abs(power) + 2));
        }
        return to2DecimalPlaces(bound);
    };

    const step = attribute.max - attribute.min !== 0 ? roundDataBound((attribute.max - attribute.min) / 100) : 1;

    function changeMinBound(value: number) {
        props.onMinBoundChanged(value);
    }

    function changeMaxBound(value: number) {
        props.onMaxBoundChanged(value);
    }

    const togglePinned = (value: boolean) => {
        props.onPinLegend(value);
    };

    return (
        <>
            <li>
                <label>
                    <div />
                    <div className="legend-limits">
                        <span>{`${roundDataBound(attribute.min)}${unit}`}</span>
                        <span>{`${roundDataBound(attribute.max)}${unit}`}</span>
                    </div>
                </label>
            </li>
            {disabled ? (
                <li>
                    <label>
                        <div />
                        <div className="horizontal-slider">{scale}</div>
                    </label>
                </li>
            ) : (
                <>
                    <li>
                        <label htmlFor="color-bar">
                            <div />
                            <ReactSlider
                                className="horizontal-slider"
                                thumbClassName="slider-thumb"
                                trackClassName="slider-track"
                                value={[colorMap.customMin, colorMap.customMax]}
                                min={attribute.min}
                                max={attribute.max}
                                ariaLabel={['Lower thumb', 'Upper thumb']}
                                ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
                                renderThumb={(renderProps) => {
                                    delete renderProps.style.zIndex;
                                    return <div {...renderProps} />;
                                }}
                                renderTrack={(renderProps, state) => {
                                    switch (state.index) {
                                        case 0:
                                            renderProps.style.backgroundColor = `#${minColor.getHexString()}`;
                                            return <div {...renderProps} />;
                                        case 1:
                                            return <div {...renderProps}>{scale}</div>;
                                        case 2:
                                            renderProps.style.backgroundColor = `#${maxColor.getHexString()}`;
                                            return <div {...renderProps} />;
                                        default:
                                            return null;
                                    }
                                }}
                                step={step}
                                minDistance={step}
                                pearling
                                onChange={(data: number[]) => {
                                    changeMinBound(data[0]);
                                    changeMaxBound(data[1]);
                                }}
                            />
                        </label>
                    </li>
                    <Input
                        title="Min"
                        value={roundDataBound(colorMap.customMin)}
                        onChange={(v) => {
                            if (v) changeMinBound(roundDataBound(v));
                            else changeMinBound(attribute.min);
                        }}
                        step={step}
                        unit={unit}
                    />
                    <Input
                        title="Max"
                        value={roundDataBound(colorMap.customMax)}
                        onChange={(v) => {
                            if (v) changeMaxBound(roundDataBound(v));
                            else changeMaxBound(attribute.max);
                        }}
                        step={step}
                        unit={unit}
                    />
                </>
            )}
            <Toggle title="Pin Legend" checked={pinned} onChange={(v) => togglePinned(v)} />
        </>
    );
};

export const ColormapSetting = (props: { layer: LayerState & HasAttributes }) => {
    const dispatch = useDispatch();
    const eventBus = useEventBus();

    const { layer } = props;

    const attribute = useAppSelector(layersSlice.getActiveAttribute(layer));

    const colorMap = useAppSelector(layersSlice.getColorMap(layer, attribute.id));

    const [lut, setLUT] = useState(getLUT(colorMap, { samples: 256 }));

    const [curveRevision, setCurveRevision] = useState<number>(0);

    useEffect(() => {
        setLUT(getLUT(colorMap, { samples: 256 }));
    }, [colorMap]);

    if (!attribute) {
        return null;
    }

    function setColorMapName(value: COLORMAP_NAMES) {
        dispatch(layersSlice.setAttributeColorMapName({ layer, attributeId: attribute.id, value }));
        dispatch(layersSlice.setActiveAttribute({ layer, value: attribute.id }));
    }

    function setBoundsMode(value: COLORMAP_BOUNDSMODE) {
        dispatch(layersSlice.setAttributeColorMapBoundsMode({ layer, attributeId: attribute.id, value }));
        dispatch(layersSlice.setActiveAttribute({ layer, value: attribute.id }));
    }

    function changeLayerColormapDiscrete(value: boolean) {
        dispatch(layersSlice.setAttributeColorMapDiscrete({ layer, attributeId: attribute.id, value }));
        dispatch(layersSlice.setActiveAttribute({ layer, value: attribute.id }));
    }

    function changeLayerColormapInvert(value: boolean) {
        dispatch(layersSlice.setAttributeColorMapInvert({ layer, attributeId: attribute.id, value }));
        dispatch(layersSlice.setActiveAttribute({ layer, value: attribute.id }));
    }

    function changeLayerColormapMaxBound(value: number) {
        dispatch(layersSlice.setAttributeColorMapCustomMaxBound({ layer, attributeId: attribute.id, value }));
        dispatch(layersSlice.setActiveAttribute({ layer, value: attribute.id }));
    }

    function changeLayerColormapMinBound(value: number) {
        dispatch(layersSlice.setAttributeColorMapCustomMinBound({ layer, attributeId: attribute.id, value }));
        dispatch(layersSlice.setActiveAttribute({ layer, value: attribute.id }));
    }

    function updateOpacityCurve(value: Array<CurveKnot>) {
        dispatch(layersSlice.setAttributeColorMapOpacityCurveKnots({ layer, attributeId: attribute.id, value }));
        dispatch(layersSlice.setActiveAttribute({ layer, value: attribute.id }));
    }

    return (
        <>
            <Select<COLORMAP_NAMES>
                title="Colormap"
                value={{
                    value: colorMap.name,
                    label: colorMap.name,
                }}
                onChange={(v) => setColorMapName((v as Option<COLORMAP_NAMES>).value)}
                options={Object.keys(COLORMAP_NAMES).map((name) => ({
                    value: COLORMAP_NAMES[name],
                    label: COLORMAP_NAMES[name],
                }))}
                icon="fas fa-palette"
            />
            <Toggle
                title="Custom Bounds"
                checked={colorMap.boundsMode === COLORMAP_BOUNDSMODE.CUSTOM}
                onChange={(v) => setBoundsMode(v ? COLORMAP_BOUNDSMODE.CUSTOM : COLORMAP_BOUNDSMODE.DATA)}
            />
            <Toggle title="Discrete" checked={colorMap.discrete} onChange={(v) => changeLayerColormapDiscrete(v)} />
            <Toggle title="Invert" checked={colorMap.invert} onChange={(v) => changeLayerColormapInvert(v)} />
            <LegendTool
                scale={ColormapScaleBar({ lut, id: layer.datasetId, attribute, colorMap })}
                minColor={lut[0]}
                maxColor={lut[lut.length - 1]}
                layer={layer}
                attribute={attribute}
                colorMap={colorMap}
                pinned={layer.pinLegend}
                onMaxBoundChanged={(value) => changeLayerColormapMaxBound(value)}
                onMinBoundChanged={(value) => changeLayerColormapMinBound(value)}
                onPinLegend={(value) => {
                    dispatch(layersSlice.setPinnedLegend({ layer, value }));
                    if (value) eventBus.dispatch('create-pane', { paneType: PANE.LEGEND, showExisting: true });
                }}
            />
            {hasOpacityCurve(layer) ? (
                <>
                    <hr />
                    <li>
                        <label htmlFor="opacity-curve">
                            <div />
                            <CurveEditor
                                xMin={attribute.min}
                                xMax={attribute.max}
                                yMin={0}
                                yMax={100}
                                knots={colorMap.opacityCurve?.knots}
                                onChange={(knots) => updateOpacityCurve(knots)}
                                revision={curveRevision}
                            />
                        </label>
                    </li>
                    <Button
                        title="Reset curve to defaults"
                        icon="fas fa-arrow-rotate-left"
                        onClick={() => {
                            updateOpacityCurve(getDefaultOpacityCurveKnots());
                            setCurveRevision(curveRevision + 1);
                        }}
                    />
                </>
            ) : null}
        </>
    );
};

const OverlayColorSetting = (props: { layer: LayerState; name?: string }) => {
    const layer = props.layer as LayerState & HasOverlayColor;
    const name = props.name ?? 'Color';
    const dispatch = useDispatch();
    const color = useAppSelector(layersSlice.getOverlayColor(layer));

    function changeLayerOverlayColor(value: Color) {
        dispatch(layersSlice.setOverlayColor({ layer, value: toHex(value) }));
    }

    return <ColorPicker title={name} color={color} onChange={(c) => changeLayerOverlayColor(c)} />;
};

export type Props = {
    layer: LayerState & HasColoringMode;
};

function formatColoringMode(mode: ColoringMode) {
    switch (mode) {
        case ColoringMode.OverlayColor:
            return 'Solid color';
        case ColoringMode.Colormap:
            return 'Property';
        default:
            throw new Error('invalid state');
    }
}

export const ColorModeSetting = (props: Props) => {
    const { layer } = props;
    const id = layer.datasetId;
    const dispatch = useDispatch();

    let attributeCount = 0;
    if (hasAttributes(layer)) {
        attributeCount = useAppSelector(layersSlice.getAttributes(layer)).length;
    }
    const availableModes = useAppSelector(layersSlice.getColoringModes(layer));
    const coloringMode = useAppSelector(layersSlice.getCurrentColoringMode(layer));

    function setCurrentMode(value: ColoringMode) {
        dispatch(layersSlice.setColoringMode({ layer, value }));
    }

    return (
        <>
            {attributeCount > 0 && availableModes.length > 1 ? (
                <Select
                    title="Coloring"
                    value={{ value: coloringMode, label: formatColoringMode(coloringMode) }}
                    options={availableModes.map((mode) => ({ value: mode, label: formatColoringMode(mode) }))}
                    onChange={(m) => setCurrentMode((m as Option<ColoringMode>).value)}
                    icon="fas fa-fill-drip"
                />
            ) : null}

            {hasAttributes(layer) && attributeCount > 0 && coloringMode === ColoringMode.Colormap ? (
                <>
                    <AttributeSelector layer={layer} />
                    <ColormapSetting key={`datasetsettings-${id}-colormap`} layer={layer} />
                </>
            ) : null}

            {coloringMode === ColoringMode.OverlayColor ? (
                <OverlayColorSetting key={`datasetsettings-${id}-overlaycolor`} layer={layer} />
            ) : null}
            <hr />
        </>
    );
};

const ColoringSettings = {
    ColorModeSetting,
    ColormapSetting,
    OverlayColorSetting,
};

export default ColoringSettings;
